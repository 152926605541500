function dateFormat (date, fmt) {
  if (!fmt) {
    fmt = "yyyy-MM-dd HH:mm:ss";
  }
  let ret;
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "m+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
function getType (obj) {
  //tostring会返回对应不同的标签的构造函数
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
}

function deepClone (data) {
  var type = getType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
}

function changeImgSize (url, size) {
  if (size) {
    var f = url.substring(0, url.lastIndexOf("."));
    var s = url.substring(url.lastIndexOf("."));
    url = f + "_" + size + "x" + size + s;
  }
  return url;
};

function businessModelDesc (businessModel) {
  if (businessModel == 22) {
    return "日结转月结"
  } else if (businessModel == 23) {
    return "日结"
  } else if (businessModel == 24) {
    return "月结(30天)"
  } else if (businessModel == 25) {
    return "月结(自然月)"
  }
  return ""
};

function priceFormatter (source) {
  if (source) {
    if (source.businessModel == 22) {
      return source.prices.day + "/日，" + source.prices.month + "/月"
    } else if (source.businessModel == 23) {
      return source.prices.day + "/日"
    } else if (source.businessModel == 24 || source.businessModel == 25) {
      return source.prices.month + "/月"
    }
  }
  return "";
};

function hideName (name) {
  var newStr;
  if (name.length === 2) {
    newStr = name.substr(0, 1) + '*';
  } else if (name.length > 2) {
    var char = '';
    for (let i = 0, len = name.length - 2; i < len; i++) {
      char += '*';
    }
    newStr = name.substr(0, 1) + char + name.substr(-1, 1);
  } else {
    newStr = name;
  }
  return newStr
};

function hideIDCard (value) {
  return value.replace(/(.{6}).*(.{4})/, '$1********$2')
};

function getCurrentDate () {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours()
  let min = date.getMinutes()
  let second = date.getSeconds()
  function getZero (n) {
    let m = n > 10 ? n : "0" + n;
    return m;
  }
  return year + "年" + month + "月" + day + "日" + '' + hour + ":" + getZero(min) + ":" + getZero(second);
}

function convertStatus (status, type) {
  let result = ''
  switch (status) {
    case 1:
      result = '待审核';
      break;
    case 2:
      result = '已撤回';
      break;
    case 3:
      result = '审批通过';
      break;
    case 4:
      result = '审批拒绝';
      break;
    case 5:
      result = '等待承租商签署';
      break;
    case 6:
      result = '等待租赁商签署';
      break;
    case 7:
      result = '执行中';
      break;
    case 8:
      result = type == 'contract' ? '已关闭' : '已完成';
      break;
    case 9:
      result = '已终止';
      break;
  }
  return result
}

function convertColor (status, type) {
  if (status == 1 || status == 5 || status == 6) {
    return 'border: 1px solid #F3A40B;color: #F3A40B;'
  } else if (status == 2 || status == 4 || status == 9) {
    return 'border: 1px solid rgb(255, 137, 137);color: rgb(255, 137, 137);'
  } else if (status == 3) {
    return 'border: 1px solid #32B726;color: #32B726;';
  } else if (status == 7) {
    return 'border: 1px solid #0096FF;color: #0096FF;';
  } else if (status == 8 && type == 'contract') {
    return 'border: 1px solid #C9CDD4;color: #C9CDD4;';
  } else if (status == 8 && type == 'other') {
    return 'border: 1px solid #0096FF;color: #0096FF;';
  }
}

function isAndroidOrIOS () {
  var u = navigator.userAgent
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  if (isAndroid) {
    return 'android'
  }
  if (isiOS) {
    return 'ios'
  }
  return false
};

// 回退逻辑
function isFromApp () {
  // 检查URL中是否含有特定参数或scheme
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('from_app')
}

function historyBack (store, isHome) {
  const historyLevel = store.state.historyLevel
  if (historyLevel === 2) {
    store.dispatch('firstPage');
    history.back()
  } else if (historyLevel === 1 && isFromApp()) {
    quit()
  } else {
    store.dispatch('initPage');
    history.back()
  }
}

function quit () {
  if ((window.highMachine && typeof window.highMachine.navigateTo === 'function') || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigateTo)) {
    if (isAndroidOrIOS() == 'android') {
      window.highMachine.quit();
    } else if (isAndroidOrIOS() == 'ios') {
      window.webkit.messageHandlers.quit.postMessage(null);
    }
  } else {
    history.back()
  }
}

function callPhone (phone) {
  if (isAndroidOrIOS() == 'android') {
    window.location.href = 'tel://' + phone;
  } else if (isAndroidOrIOS() == 'ios') {
    window.webkit.messageHandlers.callPhone.postMessage(phone);
  }
}

function formatTimeDiff (timestamp) {
  const now = new Date();
  const timeDiff = now - new Date(timestamp); // 时间差，单位为毫秒

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds}秒前`;
  } else if (minutes < 60) {
    return `${minutes}分钟前`;
  } else if (hours < 24) {
    return `${hours}小时前`;
  } else {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // 月份从0开始
    const day = date.getDate();
    return `${month}-${day}`;
  }
}

// android预览跳内部浏览器，签署使用previewUrl
function previewUrl (url) {
  if (isAndroidOrIOS() == 'android') {
    window.location.href = url
    // window.highMachine.previewUrl(url);
  } else if (isAndroidOrIOS() == 'ios') {
    window.webkit.messageHandlers.previewUrl.postMessage(url);
  }
}

function previewPdf (name, url) {
  if (isAndroidOrIOS() == 'android') {
    window.highMachine.previewPdf(name, url);
  } else if (isAndroidOrIOS() == 'ios') {
    window.webkit.messageHandlers.previewUrl.postMessage(url);
  }
}

exports.install = function (Vue, options) {
  Vue.config.globalProperties.dateFormat = dateFormat

  Vue.config.globalProperties.getType = getType

  Vue.config.globalProperties.deepClone = deepClone

  Vue.config.globalProperties.changeImgSize = changeImgSize

  Vue.config.globalProperties.businessModelDesc = businessModelDesc

  Vue.config.globalProperties.priceFormatter = priceFormatter

  Vue.config.globalProperties.isAndroidOrIOS = isAndroidOrIOS

  Vue.config.globalProperties.hideName = hideName

  Vue.config.globalProperties.hideIDCard = hideIDCard

  Vue.config.globalProperties.getCurrentDate = getCurrentDate

  Vue.config.globalProperties.convertColor = convertColor

  Vue.config.globalProperties.convertStatus = convertStatus

  Vue.config.globalProperties.historyBack = historyBack

  Vue.config.globalProperties.quit = quit

  Vue.config.globalProperties.callPhone = callPhone

  Vue.config.globalProperties.isFromApp = isFromApp

  Vue.config.globalProperties.formatTimeDiff = formatTimeDiff

  Vue.config.globalProperties.previewUrl = previewUrl
  Vue.config.globalProperties.previewPdf = previewPdf

};
